import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Form, Radio } from "semantic-ui-react";
import { useHistory } from "react-router-dom";


import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";



import EditIcon from '@material-ui/icons/Edit';


import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime, dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();

  
      // Initial values Set - Start
      let [t_id,t_id_set] = useState(0)
      let [t_ind_id,t_ind_id_set] = useState(null);
      let [action,action_set] = useState('create');
      let [t_voucher_no,t_voucher_no_set] = useState('');
      let [is_warehouse,is_warehouse_set] = useState('')
      let [currency,currency_set] = useState('')
      let [is_cal_type,is_cal_type_set] = useState('individual');
      let [is_serial,is_serial_set] = useState('no');

      let [is_own,is_own_set] = useState('');
  
  
      const [serialModal,serialModalSet] = useState(false)
      const [serial_number,serial_number_set] = useState('')
  
      let [customers,customersSet] = useState([]);
      let [branches,branches_set] = useState([]);
      let [toAccs,toAccsSet] = useState([]);
      let [serials,serialsSet] = useState([]);
      let [availableSerials,availableSerialsSet] = useState([]);

      let [items,itemsSet] = useState([]);
      let [itemCart,itemCartSet] = useState([])
      let [payCart,payCartSet] = useState([])
      let [warehousesFrom,warehousesFromSet] = useState([])
      let [warehousesTo,warehousesToSet] = useState([])
      let [transportAccs,transportAccsSet] = useState([])
  
      
      let [discountAccs,discountAccsSet] = useState([])
      let [taxAcc,taxAccSet] = useState([])
      let [loadingSave,loadingSaveSet] = useState(false)
  
      
  
      let [selectedItem,selectedItemSet] = useState(null);
      let [selectedCustomer,selectedCustomerSet] = useState(null);
      let [selectedFromAcc,selectedFromAccSet] = useState(null);

      let [selectedBranchTo,selectedBranchToSet] = useState(null);
      let [selectedWarehouseFrom,selectedWarehouseFromSet] = useState(null);
      let [selectedWarehouseTo,selectedWarehouseToSet] = useState(null);
  

      let [per_conversion,per_conversion_set] = useState(0)

      let [selectedDiscountAcc,selectedDiscountAccSet] = useState(null);
      let [selectedTaxAcc,selectedTaxAccSet] = useState(null);
      let [selectedTransportAcc,selectedTransportAccSet] = useState(null);
      const [created_date, created_date_set] = useState(currentDateTime);
  
      
  
      let [orderId,orderIdSet] = useState(0);


      let [item_qty,item_qty_set] = useState('');
      let [item_rate,item_rate_set] = useState('');
      let [item_discount_per,item_discount_per_set] = useState(0);
      let [item_discount,item_discount_set] = useState(0);
      let [item_tax_per,item_tax_per_set] = useState(0);
      let [item_tax,item_tax_set] = useState(0);
      let [item_total,item_total_set] = useState(0);
  
      let [retail_qty,retail_qty_set] = useState(0);
      let [t_qty,t_qty_set] = useState(0);
      let [t_rate,t_rate_set] = useState(0);
      let [conversion,conversion_set] = useState(0)
  
  
      let [total_discount,total_discount_set] = useState(0);
      let [total_discount_per,total_discount_per_set] = useState(0);
      let [total_tax,total_tax_set] = useState(0);
      let [total_tax_per,total_tax_per_set] = useState(0);
  
  
  
      let [sub_total,sub_total_set] = useState(0);
      let [total_transport_cost,total_transport_cost_set] = useState(0);
      let [total_amount,total_amount_set] = useState(0);
      let [paid_amount,paid_amount_set] = useState(0);
      let [due_amount,due_amount_set] = useState(0);

      

      let [tran_amount,tran_amount_set] = useState('');
  
      let [narration,narration_set] = useState('');
  
  
      const [item_name, item_name_set] = useState("");
      const [customer_name, customer_name_set] = useState("");
      const [acc_name, acc_name_set] = useState("General Customer");
      const [contact_no, contact_no_set] = useState("");
      const [institution_name, institution_name_set] = useState("");
      const [address, address_set] = useState("");

      const [to_acc_name, to_acc_name_set] = useState("");
      const [payCartShow, payCartShowSet] = useState(false);




      let [units,unitsSet] = useState([]);
      let [selectedUnit,selectedUnitSet] =  useState(null);
      
      useEffect(()=>{
        if(selectedItem != null){
          unitsSet(selectedItem.units)

          if(t_ind_id == null){
            selectedUnitSet(selectedItem.units[0])

          }
        }else{
          unitsSet([])
          selectedUnitSet(null)
        }
      },[selectedItem])

      useEffect(()=>{
        if(selectedItem != null && selectedUnit == null ){
           selectedUnitSet(selectedItem.units[0])

          
        }

        if(selectedUnit != null){
          per_conversion_set(selectedUnit.conversion)
        }
      },[selectedUnit])
      

      let [itemInfo,itemInfoSet] = useState({
        current_qty : 0,
        display_qty : '',
        rate : 0,
      })


      // Init value End

 // API Requests - Start
  useEffect(()=>{
    t_id_set(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0);
    currentRouteSet(pathSpliter(location.pathname,1));
   
    is_warehouse_set(authInfo.is_warehouse)
    currency_set(authInfo.currency)
    is_cal_type_set(authInfo.is_cal_type)
    if(authInfo.is_warehouse == 'yes'){
      getWarehouses()
    }
 

    getDiscountAccs()
    getTransportAccs()
    getTaxAccs()
    getTransferInv()
    getBranches()
    item_ref.current.focus()
    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      loadingSaveSet(true)
      getPurchaseRecordWithDetail();
    }
  },[]);

  useEffect(()=>{
    getItems()
  },[item_name])

  useEffect(()=>{
    if(selectedItem != null){
    axios.post(`${API_URL}/api/get-stock`,{itemId:selectedItem.item_id,warehouseId: selectedWarehouseFrom != null ? selectedWarehouseFrom.warehouse_id : 0},{headers:{'auth-token':authInfo.token}}).then((res)=>{
     
  
      itemInfoSet({
        current_qty : res.data[0].current_qty,
        rate : res.data[0].average_rate,
        display_qty : res.data[0].display_qty,
      })
      
    });
    }
  },[selectedItem,selectedWarehouseFrom])
 


    useEffect(()=>{
        getFromAccs()
        },[to_acc_name])

        let getFromAccs = async ()=>{
            await  axios.post(`${API_URL}/api/get-accounts-by-search`,{query:to_acc_name.trim()},{headers:{'auth-token':authInfo.token}}).then(res=>{
                toAccsSet(res.data) 
              })
            }


  let getPurchaseRecordWithDetail = async ()=>{
      let url = `/api/get-transfer-record-with-details`
      let para = {t_id:pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0}
    
    await axios.post(`${API_URL}${url}`,para,{headers:{'auth-token':authInfo.token}}).then(res=>{
      let data = res.data[0];
      let dataDetails = res.data[0].details;

       t_voucher_no_set(data.t_voucher_no);
       created_date_set(data.created_date);
       narration_set(data.narration);
       sub_total_set(data.sub_total);
     
       total_transport_cost_set(data.total_transport_cost);
       total_amount_set(data.total_amount);
       is_serial_set(data.is_serial)
       selectedBranchToSet({branch_id: data.to_branch_id,branch_name: data.to_branch_name,branch_address : data.to_branch_address})
       
       selectedTransportAccSet({acc_id:data.transport_acc_id,acc_name: data.transport_acc_name})
       

       // Detail Data to Product Cart - Start
      let itemCartData =  dataDetails.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }

          let updateItem = {
            from_warehouse_id : item.from_warehouse_id,
            from_warehouse_name : item.from_warehouse_name,
            to_warehouse_id : item.to_warehouse_id,
            to_warehouse_name : item.to_warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            item_qty:item.item_qty,
            item_rate:item.item_rate,
            item_discount:item.item_discount,
            item_discount_per:item.item_discount_per,
            discount_acc_id:item.discount_acc_id,
            discount_acc_name:item.discount_acc_name,
            item_tax:item.item_tax,
            item_tax_per:item.item_tax_per,
            tax_acc_id:item.tax_acc_id,
            tax_acc_name:item.tax_acc_name,
            serials,
            item_total:item.item_total,
            retail_qty:item.retail_qty,
            t_qty:item.t_qty,
            t_rate:item.t_rate,
            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            conversion: item.conversion,

            units: item.units,
            per_symbol : item.unit_symbol,
            per_conversion : item.per_conversion,
            per_unit_id : item.per_unit_id,
            per_unit_symbol: item.per_unit_symbol
          }
          return updateItem;
        });
        itemCartSet(itemCartData);

        // Detail Data to Product Cart - End

     

        // tran cart end

        loadingSaveSet(false)
    });
  }


  useEffect(()=>{
    if(selectedItem != null && selectedItem.is_serial == 'yes'){
       axios.post(`${API_URL}/api/get-available-serials`,{itemId:selectedItem.item_id,warehouseId: selectedWarehouseFrom != null ? selectedWarehouseFrom.warehouse_id:0},{headers:{'auth-token':authInfo.token}}).then((res)=>{
        availableSerialsSet(res.data)
      });
    }
  },[selectedItem,selectedWarehouseFrom])



 

 


        let getTransferInv = async ()=>{
          await  axios.get(`${API_URL}/api/get-transfer-voucher-no`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            t_voucher_no_set(res.data)
      
          })
        }

      let getDiscountAccs = async ()=>{
      // await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_income'},{headers:{'auth-token':authInfo.token}}).then(res=>{
      //     discountAccsSet(res.data)
      //     selectedDiscountAccSet(res.data.length != 0 ? res.data[0]:null)

      //   })
      }

      let getTransportAccs = async ()=>{
        await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_expense'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          transportAccsSet(res.data)
          selectedTransportAccSet(res.data.length != 0 ? res.data[0]:null)

        })
      }




      let getTaxAccs = async ()=>{
      // await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'dutie_&_tax'},{headers:{'auth-token':authInfo.token}}).then(res=>{
      //     taxAccSet(res.data) 
      //     selectedTaxAccSet(res.data.length != 0 ? res.data[0]:null)
      //   })
      }




const getBranches = async() => {
  await axios.post(`${API_URL}/api/get-branches`,{"select-type":"active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
     branches_set(res.data.message)
  })
};

 
  const getWarehouses = async() => {
      await axios.post(`${API_URL}/api/get-warehouses`,{"select-type":"active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
        warehousesFromSet(res.data.message)
        warehousesToSet(res.data.message)
      })
  };

  const getItems = async() => {
      await axios.post(`${API_URL}/api/get-items-by-search`,{query:item_name.trim(),search:true},{headers:{'auth-token':authInfo.token}}).then(res=>{
         itemsSet(res.data)
      })
  };

   // API Requests - End

 


    useEffect(()=>{
      item_discount_per_set(0)
      item_discount_set(0)
      item_tax_per_set(0)
      item_tax_set(0)

      total_discount_set(0)
      total_discount_per_set(0)
      total_tax_per_set(0)
      total_tax_per_set(0)
      total_transport_cost_set(0)

    },[is_cal_type])

    // Initial values Set - Start


    // Input Refs - Start
    let warehouse_ref = useRef(null)
    let item_ref = useRef(null)
    let item_qty_ref = useRef(null)
    let item_rate_ref = useRef(null)
    let retail_qty_ref = useRef(null)

    
    let item_discount_ref = useRef(null)
    let item_discount_per_ref = useRef(null)
    let item_tax_ref = useRef(null)
    let item_tax_per_ref = useRef(null)

    let acc_name_ref = useRef(null)
    let institution_name_ref = useRef(null)
    let contact_no_ref = useRef(null)
    let address_ref = useRef(null)
    let created_date_ref = useRef(null)
    let total_transport_cost_ref = useRef(null)

    let total_discount_ref = useRef(null)
    let total_discount_per_ref = useRef(null)
    let total_tax_ref = useRef(null)
    let total_tax_per_ref = useRef(null)


    let to_acc_ref = useRef(null)
    let tran_amount_ref = useRef(null)

    let paid_amount_ref = useRef(null)
    let due_amount_ref = useRef(null)
    
    // Input Refs - End

    useEffect(()=>{
        if(paid_amount != '' && paid_amount != 0){
            payCartShowSet(true)
        }else{
            payCartShowSet(false)
        }
    },[paid_amount])

    // Input Calculations - Start
    useEffect(()=>{
            let total_qty = item_qty * conversion;
            total_qty += parseFloat(retail_qty)
            let perQtyRate = per_conversion > 1? item_rate / conversion : (item_rate * conversion) / conversion;

                t_rate_set(perQtyRate);
                t_qty_set(total_qty);
           
        let  total = parseFloat(total_qty * perQtyRate).toFixed(4);

       
        
        item_total_set(parseFloat(total).toFixed(4))

    },[selectedItem,selectedUnit,item_qty,item_rate,retail_qty])


    useEffect(()=>{
            let total_qty = item_qty * conversion;
            total_qty += parseFloat(retail_qty)
            let perQtyRate = per_conversion > 1? item_rate / conversion : (item_rate * conversion) / conversion;

            t_rate_set(perQtyRate);
            t_qty_set(total_qty);
           
        let  total = parseFloat(total_qty * perQtyRate).toFixed(4);

       
        item_total_set(parseFloat(total).toFixed(4));
        
        },[item_discount,item_tax,item_discount_per,item_tax_per])

  
    useEffect(()=>{
    
     let subTotal =  itemCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.item_total)
      },0);
      sub_total_set(parseFloat(subTotal).toFixed(4));


        
        // Calculate Tax
       


        subTotal += parseFloat(total_transport_cost)

        total_amount_set(parseFloat(subTotal).toFixed(4))




        let checkIsSerial =   itemCart.findIndex((ele)=>{
          if(ele.is_serial=='yes'){ 
            return true 
          }else{
            return false
          }
          }); 
      
          if(checkIsSerial>-1){
            is_serial_set('yes')
          }else{
            is_serial_set('no')
          }


          if(is_cal_type == 'individual'){
            let itemDiscount =  itemCart.reduce((prev,curr)=>{
              return prev+parseFloat(curr.item_discount)
            },0);
    
            total_discount_set(parseFloat(itemDiscount).toFixed(4))
    
            let itemTax =  itemCart.reduce((prev,curr)=>{
              return prev+parseFloat(curr.item_tax)
            },0);
            total_tax_set(parseFloat(itemTax).toFixed(4))
          }

    },[itemCart]);


 


    useEffect(()=>{


        total_amount_set(parseFloat(parseFloat(total_transport_cost) + parseFloat(sub_total)).toFixed(4))
    },[total_transport_cost])



    useEffect(()=>{
      if(selectedItem != null){
      axios.post(`${API_URL}/api/get-stock`,{itemId:selectedItem.item_id,warehouseId : selectedWarehouseFrom != null ? selectedWarehouseFrom.warehouse_id : 0},{headers:{'auth-token':authInfo.token}}).then((res)=>{
         
        item_rate_set(res.data[0].average_rate * res.data[0].conversion)
        
      });
      }
    },[selectedItem])


    // Input Calculations - End

    // Methods  -  start
  

       // Data Save Method
      
    const savePurchaseOrder = async ()=>{
          if(itemCart.length == 0){
            swal({
              title:`Item cart is Empty !`,
              icon:'warning'
            })
            return false
          }else if(selectedBranchTo == null){
            swal({
              title:`Select Branch !`,
              icon:'warning'
            })
            return false
          }else{
           
            let transferData = {
                t_id,
                itemCart,
                
                masterData:{
                  to_branch_id :selectedBranchTo.branch_id,
                  t_voucher_no,
                  is_serial,
                  created_date,
                  narration,
                  sub_total,
                  total_transport_cost,
                  total_amount,
                 
                  transport_acc_id : selectedTransportAcc != null ? selectedTransportAcc.acc_id : 0,

                   
                }
            }

        

            

        let url = `/api/create-transfer`
        if(pathSpliter(location.pathname,3) != undefined ){
            url = `/api/update-transfer`
        }
           loadingSaveSet(true) 
         await axios.post(`${API_URL}${url}`,transferData,{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadingSaveSet(false)

          if(res.data.error){
            swal({
              title: `${res.data.message}`,
              icon:'warning'
            })
            return false;
          }
          if(!res.data.error){
            swal({title:`${res.data.message}. Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
              if(confirm){
                history.push(`/inventory/quick-transfer-voucher/${res.data.t_id}`)
               
              }else{
                window.location.reload()
              }
            })
          }

          

         })


          }

            
          
          
    }


      // Item cart methods - Start

  const addTOCart = ()=>{

    if(selectedItem ==null){
      swal({
        title:`Select Item`,
        icon:'warning'
      });
      item_ref.current.focus()
      return false
    }
    
    if(is_warehouse == 'yes' && is_own =='yes' && (selectedWarehouseFrom == null || selectedWarehouseTo == null)){
      swal({
        title:`Select Warehouse`,
        icon:'warning'
      });
      return false
    }
    //  if(item_qty < 1){
    //   swal({
    //     title:`Invalid Quantity !`,
    //     icon:'warning'
    //   });
    //   item_qty_ref.current.focus()
    //   return false
    // }




    if( selectedItem.is_serial == 'yes' && serials.length == 0  ){
      swal({title:'Serial  cart is Empty.',icon:'warning'});return false;
    }

    if( selectedItem.is_serial == 'yes' && serials.length !=  t_qty  ){
      swal({title:'Serial  & Item quantity   should be same',icon:'warning'});return false;
    }


    let obj = {
      from_warehouse_id : selectedWarehouseFrom != null ? selectedWarehouseFrom.warehouse_id :0,
      from_warehouse_name : selectedWarehouseFrom != null ? selectedWarehouseFrom.warehouse_name :'',
      to_warehouse_id : selectedWarehouseTo != null ? selectedWarehouseTo.warehouse_id :0,
      to_warehouse_name : selectedWarehouseTo != null ? selectedWarehouseTo.warehouse_name :'',
      item_id : selectedItem.item_id,
      item_name : selectedItem.item_name,
      display_text : selectedItem.display_text,
      is_serial: selectedItem.is_serial,
      item_qty,
      item_rate,
      serials,
      item_total,

      retail_qty,
      t_qty,
      t_rate,
      qty_display: item_qty +' '+ selectedItem.unit_symbol + (conversion >1 ? ', '+ retail_qty +' ' +selectedItem.base_unit_name:''),
      unit_symbol: selectedItem.unit_symbol,
      base_unit_name: selectedItem.base_unit_name,
      unit_name: selectedItem.unit_name,
      conversion: conversion,

      units,
      per_unit_symbol : selectedUnit.unit_symbol,
      per_conversion : selectedUnit.conversion,
      per_unit_id : selectedUnit.unit_id,
   }



   

    let checkExists =   itemCart.findIndex((ele)=>{
        let fromWarehouseId = selectedWarehouseFrom == null ? 0 : selectedWarehouseFrom.warehouse_id;
        let toWarehouseId = selectedWarehouseTo == null ? 0 : selectedWarehouseTo.warehouse_id;
        if(ele.item_id==selectedItem.item_id && ele.from_warehouse_id == fromWarehouseId && ele.to_warehouse_id == toWarehouseId){ 
          return true 
        }else{
          return false
        }
    }); 

    if(checkExists>-1 && t_ind_id != null){
      let preCopy =  [...itemCart];
      preCopy[t_ind_id] = obj
      itemCartSet(preCopy)
    }else{
      itemCartSet([...itemCart,obj])
    }


     selectedWarehouseFromSet(null)
     selectedWarehouseToSet(null)
     selectedItemSet(null)
     item_qty_set('')
     item_rate_set('')
     item_discount_set(0)
     item_discount_per_set(0)

     t_qty_set(0)
     t_rate_set(0)
     retail_qty_set(0)

     item_tax_set(0)
     item_tax_per_set(0)

     t_ind_id_set(null)

     serialsSet([])
     item_name_set('')


      item_ref.current.focus()

    }


    const editItemCart = (row,sl)=>{
       t_ind_id_set(sl)
       selectedWarehouseFromSet({warehouse_id:row.from_warehouse_id,warehouse_name:row.from_warehouse_name});
       selectedWarehouseToSet({warehouse_id:row.to_warehouse_id,warehouse_name:row.to_warehouse_name});
       selectedItemSet({item_id:row.item_id,item_name:row.item_name,display_text:row.display_text,is_serial:row.is_serial,
        unit_symbol:row.unit_symbol,base_unit_name:row.base_unit_name,conversion:row.conversion,base_unit_name:row.base_unit_name,unit_name:row.unit_name,
        discount_per: row.discount_per,tax_per: row.tax_per,units: row.units});


        selectedUnitSet({unit_symbol : row.per_unit_symbol,conversion : row.per_conversion,unit_id : row.per_unit_id})
        per_conversion_set(row.per_conversion)

       serialsSet(row.serials)
       item_qty_set(row.item_qty)
       item_rate_set(row.item_rate)
       retail_qty_set(row.retail_qty)
       t_qty_set(row.t_qty)
       t_rate_set(row.t_rate)
       conversion_set(row.conversion)

    }


    const itemCartItemRemove = (sl)=>{
      let preCopy =  [...itemCart];
      preCopy.splice(sl, 1);
      itemCartSet(preCopy)
    }
  
      // Item cart methods - End

      // Item serial - methods
      const serialAdd = async ()=>{
        if(serial_number.trim() == ''){
          swal({
            title:`Serial Number is Required.`,
            icon:'warning'
          })
        }else{
         let serialCartCheck =   serials.findIndex((item)=>{
            return item.serial_number == serial_number.trim()
          });
  
          
  
  
          if(serialCartCheck > -1){
            swal({
              title:`Serial Number is exists on this serial cart `,
              icon:'warning'
            })
            return false
          }
          
          let itemCartCheck =   itemCart.findIndex((item)=>item.serials.some(s=> s.serial_number == serial_number.trim()));
  
          if(itemCartCheck > -1){
            swal({
              title:`Serial Number is exists on Item cart `,
              icon:'warning'
            })
          return false
          }else{
  
            let checkPreviousStock = false;
             await axios.post(`${API_URL}/api/check-serial-number`,{serial_number:serial_number.trim()},{headers:{'auth-token':authInfo.token}}).then((res)=>{
                 if(res.data.error){
                  checkPreviousStock = true
                 }
            });
  
            if(checkPreviousStock){
              swal({
                title:`Serial Number  exists on previous sales or order`,
                icon:'warning'
              });
              return false;
            }else{
              serialsSet([...serials,{serial_number:serial_number.trim()}])
              serial_number_set('');
            }
            
  
          }
          
        }
    }


    const serialRemove = (ind)=>{
      let hardCopy =  [...serials];
      hardCopy.splice(ind, 1);
      serialsSet(hardCopy)
    }
    
      // Item serial methods - end
    
    // Methods  -  End

 


     useEffect(()=>{
      if(selectedWarehouseFrom != null){
       let warehouses =  warehousesFrom.filter((w)=>{
          return selectedWarehouseFrom.warehouse_id != w.warehouse_id
        })

        warehousesToSet(warehouses)
      }
     },[selectedWarehouseFrom])

  

 

     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Transfer    {pathSpliter(location.pathname,3) == undefined ? 'Entry' :"Update"}  </h2>
      <Grid container spacing={2}>
      <Grid  xs={12} sm={3}>
      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',padding:'1px',color:'#222'}}>Item cart / Product cart Information</p>


      {
        is_warehouse == 'yes' ?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={warehousesFrom} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.warehouse_name}
              value={selectedWarehouseFrom}
              onChange={(event,selectedObj)=>{
                selectedWarehouseFromSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={warehouse_ref}
                  onKeyDown={(e)=>{
                    if(e.key==='Enter'){
                      item_ref.current.focus()
                    }
                  }}
                  {...params}
                  label="Select From Warehouse"
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
        
        </>):''
      }





      
      {/* <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold'}}>Select a Item (By Search)</p> */}
      <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      
      {
     selectedItem != null ? <>
       {
          itemInfo.current_qty > 0 ?(<p className="stock-availity">Available  : {itemInfo.display_qty}</p>)
          :(<p className="stock-unavaility">Unavailable  : {itemInfo.display_qty}</p>) 
       }
     </>:''
    }

      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          style={{ width: '100%' }}
          options={items} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.display_text}
          value={selectedItem}
          onChange={(event,selectedObj)=>{
            selectedItemSet(selectedObj)
            selectedUnitSet(null)
            if(selectedObj != null){
              item_discount_per_set(selectedObj.discount_per)
              item_tax_per_set(selectedObj.tax_per)
              conversion_set(selectedObj.conversion)

              per_conversion_set(selectedObj.units[0].conversion)

              item_rate_set(selectedObj.item_rate)

            }else{
              item_rate_set('')
            }
            
            item_qty_set('')
            retail_qty_set(0)
          }}
          renderInput={(params) => (
            <TextField
              inputRef={item_ref}
              onKeyDown={(e)=>{
                if(e.key==='Enter'){
                  item_qty_ref.current.focus()
                }
              }}
              onChange={(e)=>item_name_set(e.target.value)}
              {...params}
              label="Search Item"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>



      {
          selectedItem != null && selectedItem.conversion > 1 ?(<>
          
          <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={units} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.unit_symbol}
              value={selectedUnit}
              onChange={(event,selectedObj)=>{
                selectedUnitSet(selectedObj)
                if(selectedObj != null){
                  per_conversion_set(selectedObj.conversion)

                }
              }}
              renderInput={(params) => (
                <TextField
                
                  {...params}
                  label="Per "
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
          </>):''
        }




      <Grid container xs={12} sm={12} >


      {
selectedItem != null && selectedItem.is_serial == 'yes' ?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          multiple
          limitTags={10}
          id="multiple-limit-tags" 
  
          style={{ width: '100%' }}
          options={availableSerials} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.serial_number}
          value={serials}
          onChange={(event,selectedObj)=>{
            serialsSet(selectedObj)
         
          
          }}
          renderInput={(params) => (
            <TextField
            
              {...params}
              label="Select Serials"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>

</>):''
}






      </Grid>


      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',marginBottom:'5px'}}>{
           selectedItem != null &&  conversion > 1  ?  selectedItem.unit_name :''
          }</p>

      <Grid container xs={12} sm={12} >
      
          
      <Grid item xs={12} sm={5}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_qty_set(0):item_qty_set(e.target.value)}
            autoComplete='off' className={classes.fullWidth} 
          label={`QTY ${selectedItem != null &&  selectedItem.item_id!=0?'('+selectedItem.unit_symbol+')':''}`} name="item_qty" value={item_qty} 
          inputRef={item_qty_ref}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              if( conversion > 1){
                retail_qty_ref.current.focus()
              }else{
                addTOCart()
              }
             
            }
          }}
          variant="outlined" size="small" onChange={(e)=> item_qty_set(e.target.value)} />
                
                         
            </Grid>

            <Grid item xs={1} sm={1} ></Grid>
{
  conversion > 1 ?(<>
            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
           
            autoComplete='off' className={classes.fullWidth} 
          label={` ${selectedItem != null ? selectedItem.base_unit_name:''}  `} name="retail_qty" value={retail_qty} 
          inputRef={retail_qty_ref}
          onBlur={(e)=>e.target.value=='' || e.target.value < 0?retail_qty_set(0):retail_qty_set(e.target.value)}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              item_rate_ref.current.focus()
            }
          }}
          variant="outlined" size="small" onChange={(e)=> retail_qty_set(e.target.value)} />
                
                         
            </Grid>
  </>):''

}
        


            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            inputRef={item_rate_ref}
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_rate_set(0):item_rate_set(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                addTOCart()
              }
            }}
            disabled
           autoComplete='off' className={classes.fullWidth} 
         label="Rate" name="item_rate" value={item_rate} 
         variant="outlined" size="small" onChange={(e)=>item_rate_set(e.target.value)} />
               
                         
            </Grid>

        
       </Grid>
   
    
        
         
         

    

            <Grid item xs={12} sm={12} >
            <TextField type="number"  
           disabled
           autoComplete='off' className={classes.fullWidth} 
         label="Total" name="item_total" value={item_total} 
         variant="outlined" size="small" onChange={(e)=>item_total_set(e.target.value)} />
               
                         
            </Grid>


            {
        is_warehouse == 'yes' && is_own == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px',marginTop:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={warehousesTo} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.warehouse_name}
              value={selectedWarehouseTo}
              onChange={(event,selectedObj)=>{
                selectedWarehouseToSet(selectedObj)

                if(selectedObj != null && is_warehouse == 'yes' && is_own =='yes' && selectedWarehouseFrom == null){
                   swal({
                     title:'Select from warehouse',
                     icon:'warning'
                   })
                   selectedWarehouseToSet(null)
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={warehouse_ref}
                  onKeyDown={(e)=>{
                    if(e.key==='Enter'){
                      item_ref.current.focus()
                    }
                  }}
                  {...params}
                  label="Select To Warehouse"
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
        
        </>):''
      }

        <span>Press Enter Key to sales Cart </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOCart()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Cart</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={9}>
      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',
      padding:'1px',color:'#222',marginTop:'-8px'}}>To Branch/ Item Cart & Total Amounts  Information</p>


      <Grid  container xs={12} sm={12} style={{marginBottom:'10px'}}>
      
      <Grid  item xs={12} sm={5} style={{marginBottom:'10px'}}>

        <Autocomplete 
                   options={branches} 
                   size="small"
                   classes={{
                     option: classes.option,
                   }}
                   getOptionLabel={(option) => option.branch_name}
                   value={selectedBranchTo}
                   onChange={(event,selectedObj)=>{
                    selectedBranchToSet(selectedObj)

                    if(selectedObj != null && selectedObj.branch_id == authInfo.userInfo.user_branch_id){
                      is_own_set('yes')
                    }else{
                      is_own_set('no')
                    }
                    
                    selectedWarehouseFromSet(null)
                    selectedWarehouseToSet(null)
                   }}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       label="To  Branch"
                       variant="outlined"
                     
                     />
                   )}

      
  />
      </Grid>
     

    


      <Grid  item xs={12} sm={7} style={{marginTop:'-3px',padding:'3px'}}>
        <TextField  autoComplete='off'  className={classes.fullWidth} 
         disabled={"true"}
        label="Branch Address" name="contact_no" value={selectedBranchTo != null ? selectedBranchTo.branch_address:''} 
        variant="outlined" size="small" />
      </Grid>

     


        <Grid item xs={12} sm={2} style={{marginTop:'3px',padding:'3px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
      
        label="Voucher No" name="t_voucher_no" value={t_voucher_no} disabled
        variant="outlined" size="small" onChange={(e)=>t_voucher_no_set(e.target.value)} />
        </Grid>

       

        <Grid item xs={12} sm={2} style={{float:'right',marginTop: '',marginLeft:'5px'}}> 
        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Entry Date" 
          inputFormat={dateTimeFormat}
          value={created_date}
          onChange={(e)=>created_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>
        </Grid>
       


  
  </Grid>
 



       



       
     

        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'15%'}}>Item Name</TableCell>
              {
                is_serial=='yes'?(<>
              <TableCell align="left"   style={{width:'14%'}}>Serials</TableCell>

                </>):''
              }
              
              {
                is_warehouse == 'yes'?(<>
                  <TableCell align="left"   style={{width:'10%'}}>From </TableCell>
                  <TableCell align="left"   style={{width:'10%'}}>To </TableCell>
                </>):''
              }
              <TableCell align="right"  style={{width:'10%'}}>QTY</TableCell>
              <TableCell align="right"  style={{width:'15%'}}>Rate (Per)</TableCell>
             
             
              <TableCell align="right"  style={{width:'10%'}}>Total</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                itemCart.map((item,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == t_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{item.item_name}</TableCell>

                  {
                    is_serial == 'yes'?(<>
                     <TableCell align="left">
                      {
                        item.is_serial == 'yes'?(<>
                            {
                  
                            <>
                            ({item.serials.map((serial)=>(
                          <> <span>{serial.serial_number}</span>, </> 
                          ))})
                          </>
                            
                              }
                          
                        </>):''
                      }
                  </TableCell>
                    
                    </>):''
                  }
                 {
                   is_warehouse =='yes'?(<>
                          <TableCell align="left">{item.from_warehouse_name}</TableCell>
                          <TableCell align="left">{item.to_warehouse_name}</TableCell>
                   </>):''
                 }
                  <TableCell align="right">{item.qty_display}</TableCell>
                  <TableCell align="right">{format(parseFloat(item.item_rate).toFixed(4))} ({item.per_unit_symbol})</TableCell>
                 
                
                  <TableCell align="right">{format(parseFloat(item.item_total).toFixed(4))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editItemCart(item,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{itemCartItemRemove(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                itemCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={is_serial == 'yes' && is_warehouse == 'yes'? 5 :  (is_serial == 'no' && is_warehouse == 'no') ? 3 :  ((is_serial == 'yes' && is_warehouse == 'no') ? 5 : 5) } style={{fontWeight:'bold'}}>Total  : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}></TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(itemCart,(item)=>parseFloat(item.item_total))).toFixed(4))}</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>

    <Grid container xs={12} sm={12} style={{marginTop:'20px'}} >
            <Grid container  xs={12} sm={6}>
           

               
        

               
            

   

         {/*  */}


             
        <Grid container xs={12} sm={12}>

        <Grid item xs={12} sm={12}>
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell><TextareaAutosize 
            
              
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',width: '100%'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>

    </Grid>

        </Grid>






              

            </Grid>
            <Grid item  xs={12} sm={1}></Grid>
            <Grid  container  xs={12} sm={5} >
               
               <Grid item  xs={12} sm={1}></Grid>
               <Grid item  xs={12} sm={5} style={{marginBottom:'10px'}}>
               <TextField  type="number" disabled autoComplete='off' disabled className={classes.fullWidth} 
                label={`Sub Total (${currency})`} name="sub_total" value={sub_total} 
                variant="outlined" size="small" onChange={(e)=>sub_total_set(e.target.value)}   />
               </Grid>

              
     

        <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={transportAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedTransportAcc}
              
              onChange={(event,selectedObj)=>{
                selectedTransportAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
            
                  {...params}
                  label="Choose a Transport Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>




        <Grid item  xs={12} sm={5} style={{marginBottom:'10px'}}>
               <TextField  type="number"  autoComplete='off'  className={classes.fullWidth} 
               inputRef={total_transport_cost_ref}
            
                label={`Transport Cost (${currency})`} name="total_transport_cost" required  value={total_transport_cost} 
                variant="outlined" size="small" onChange={(e)=>{
                  total_transport_cost_set(e.target.value)
                  }}
                  onBlur={(e)=>e.target.value==''?total_transport_cost_set(0):total_transport_cost_set(e.target.value)}
                  />
               </Grid>

               <Grid item  xs={12} sm={2} >

                 </Grid>
               <Grid item  xs={12} sm={5} >
               <TextField  type="number" disabled  autoComplete='off'  className={classes.fullWidth} 
                label={`Grand Total  (${currency})`} name="total_amount" value={total_amount} 
                variant="outlined" size="small" onChange={(e)=>total_amount_set(e.target.value)}/>
               </Grid>


               <Grid container  xs={12} sm={12}>

               <Grid item  xs={12} sm={6}>
                  <Button style={{marginTop: '25px',fontSize: '12px'}}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={loadingSave}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      onClick={savePurchaseOrder}
                    >
                      Save Transfer
                    </Button>
               </Grid>

               <Grid item  xs={12} sm={1}>
               </Grid>

               <Grid item  xs={12} sm={5}>
               <Button 
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{color: 'white',
                  fontSize:'14px',
                    backgroundColor: '#115f5f',
                    marginTop: '30px'}}
                  disabled={loadingSave}
                  onClick={()=>{
                    window.location.reload();
                    return false;
                  }}
                >
                  Reset
                </Button>
               </Grid>
              
               </Grid>
              
              



            </Grid>
    </Grid>

  


        </Grid>



        </Grid>

      </Grid>

   
  

     </Paper>











       {/* Switch Branch  Modal */}
   <Modal
        open={serialModal}
        onClose={() => serialModalSet(false)}
        center
        style={{minWidth:'600px !important',minHeight:'500px'}}
 
      
      >
        <h2 style={{margin: 0,
    padding: 0,
    color: '#0f7e77'}}>Serial Number Add of <p style={{fontSize:'10px',margin: 0,
    padding: 0,color:'red'}}>{selectedItem != null ? selectedItem.item_name :''}</p></h2>
        <Grid item xs={12} sm={12} style={{display:'flex',marginTop:'5px',  display: 'flex',
   }} > 
      
        <Grid item  xs={12} sm={10}>
            <TextField label="Serail Number" variant="outlined"  size="small" 
             className={classes.fullWidth} value={serial_number} 
              onChange={(e)=>serial_number_set(e.target.value)}
              name="serial_number" 
             onKeyDown={(e)=>{
                  if(e.key == 'Enter'){
                    serialAdd()
                  }
             }}
              />
          </Grid> 

          <Grid item  xs={12} sm={2}>
              <Button style={{background:'green',fontSize:'20px',
               background: '#03d4be',
    fontSize: '15px'}}
    onClick={()=>serialAdd()}
    >Add</Button> 
          </Grid> 
      
          



         
        </Grid>


        <br/>
          <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Serial</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        
        
        {
            serials.map((serial,ind)=>(
              <TableRow>
              <TableCell align="left">{ind+parseFloat(1)}</TableCell>
              <TableCell align="left">{serial.serial_number}</TableCell>
              <TableCell align="right"  ><span style={{color:'red',fontSize:'18px',cursor:'pointer',margin:0,padding:0}} onClick={()=>serialRemove(ind)}>X</span></TableCell>
            </TableRow>
            ))
          }
        



        </TableBody>
      </Table>
    </TableContainer>



      </Modal>


     

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    fontSize:'10px',
    backgroundColor: '#115f5f'
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#240275',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '-8px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);
import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
    },[]);
    let [items,itemsSet] = useState([]);

    let [customers,customersSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    let [groups,groupsSet] = useState([]);
    let [categories,categoriesSet] = useState([]);
    let [users,usersSet] = useState([]);
    let [reportLoading,reportLoadingSet] = useState(false);
    
    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'All'})
    let [selectedCustomer,selectedCustomerSet] = useState(null)
    let [selectedItem,selectedItemSet] = useState(null)
    let [selectedGroup,selectedGroupSet] = useState(null)
    let [selectedCategory,selectedCategorySet] = useState(null)
    let [selectedLocation,selectedLocationSet] = useState(null)
    let [selectedUser,selectedUserSet] = useState(null)
    let [selectedRecordType,selectedRecordTypeSet] = useState({recordType:'without item'})

    
    let [filterResult,filterResultSet] = useState([]);
    let [filterResultDetails,filterResultDetailsSet] = useState([]);
    let [details,detailsSet] = useState([]);
    let [groupListOfItems,groupListOfItemsSet] = useState([]);
    let [groupWiseItems,groupWiseItemsSet] = useState([]);
    let [categoryWiseItems,categoryWiseItemsSet] = useState([]);
    
    

    let [print,printSet] = useState(false);
    let [loadingItems,loadingItemsSet] = useState(false);
    let [loadingCustomers,loadingCustomersSet] = useState(false);
    let [group_of_item,group_of_item_set] = useState(false);
    let [item_name,item_name_set] = useState('');
    let [customer_name,customer_name_set] = useState('');
    
    let [recordTypes,recordTypesSet] = useState([{recordType:'without item'},{recordType:'with item'}])
   
  



    useEffect(()=>{
          loadingCustomersSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            customersSet(res.data)
            loadingCustomersSet(false)
          })
          
    },[customer_name])


    useEffect(()=>{
        loadingItemsSet(true)
        axios.post(`${API_URL}/api/get-items-by-search`,{query:item_name.trim()},{headers:{'auth-token':authInfo.token}}).then(res=>{
          itemsSet(res.data)
          loadingItemsSet(false)
        })
         
  },[item_name])



    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }

    const getGroups = async ()=>{
      await axios.post(`${API_URL}/api/get-item-groups`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        groupsSet(res.data)
    })
    }

    const getCategories = async ()=>{
        await axios.post(`${API_URL}/api/get-item-categories`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          categoriesSet(res.data)
      })
      }

      const getUsers = async ()=>{
        await axios.post(`${API_URL}/api/get-users`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
          usersSet(res.data.message)
      })
      }

    let [byDateTime,byDateTimeSet] = useState({
          dateTimeFrom: currentDateStartTime(),
          dateTimeTo:currentDateEndTime()
    })


    let salesOrderDelete = async (sale_r_id)=>{
        swal({
           title:'Are you sure delete this?',
           icon:'warning',
           buttons:true
        }).then(async (confirm)=>{
          if(confirm){
            await axios.post(`${API_URL}/api/sales-return-delete`,{sale_r_id:sale_r_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
              if(!res.data.error){
               swal({
                 title:res.data.message,
                 icon:'success'
              })
              getSearchResult()
              } 
         })
          }else{
            return false
          }
          
        })
        
    }

    useEffect(()=>{ 

        
        if(selectedFilterType != null && selectedFilterType.filterType=='By Group' && groups.length == 0){
          getGroups();
        } 

        if(selectedFilterType != null && selectedFilterType.filterType=='By Category' && categories.length == 0){
            getCategories();
        } 

        if(selectedFilterType != null && selectedFilterType.filterType=='By User' && users.length == 0 ){
            getUsers();
        }

        if(selectedFilterType != null && locations.length == 0 &&  (selectedFilterType.filterType=='By Location'  || selectedFilterType.filterType=='By Item')){
          getLocations();
        }

        filterResultSet([])
        filterResultDetailsSet([])
        detailsSet([])
        groupListOfItemsSet([])
        groupWiseItemsSet([])
        categoryWiseItemsSet([])

        selectedCustomerSet(null)
        selectedItemSet(null)
        selectedGroupSet(null)
        selectedCategorySet(null)
        selectedLocationSet(null)
        selectedUserSet(null)
       


    },[selectedFilterType,selectedRecordType]);



    let [filterTypes,filterTypesSet] = useState([{filterType:'All'},
    {filterType:'By Customer'},
    {filterType:'By Item'},
    {filterType:'By Group'},
    {filterType:'By Category'},
    {filterType:'By Location'},
    {filterType:'By User'}]);

    
    let getSearchResult = async ()=>{

      if(selectedFilterType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-sales-return-record`;

            if(selectedRecordType != null && selectedRecordType.recordType == 'with item' ){ 
                url = `${API_URL}/api/get-sales-return-record-with-details`
            }
            if(selectedFilterType!=null && (selectedFilterType.filterType=='By Item' || selectedFilterType.filterType=='By Group' || selectedFilterType.filterType=='By Category')){
              url = `${API_URL}/api/get-sales-return-details`
            }


            
        let reqPayload = {
            selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,
            locationId: selectedLocation != null? selectedLocation.location_id:null,
            customerId: selectedCustomer != null? selectedCustomer.acc_id:null,
            itemId: selectedItem != null ?selectedItem.item_id:null,
            groupId: selectedGroup != null ? selectedGroup.group_id: null,
            categoryId: selectedCategory != null ? selectedCategory.category_id: null,
            userId: selectedUser != null ? selectedUser.user_id : null,
            fromDate: byDateTime.dateTimeFrom,
            toDate: byDateTime.dateTimeTo
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)
            if(selectedRecordType != null && selectedRecordType.recordType=='without item' && !(selectedFilterType.filterType=='By Item' || selectedFilterType.filterType=='By Group' || selectedFilterType.filterType=='By Category')){
              filterResultSet(res.data);
            }
            if(selectedRecordType != null && selectedRecordType.recordType=='with item' && !(selectedFilterType.filterType=='By Item' || selectedFilterType.filterType=='By Group' || selectedFilterType.filterType=='By Category')){

               let data =  res.data.map((master)=>{

                    let itemCartData =  master.details.map((item)=>{ 
                        let serials = []
                        
                        serials = item.serials.split(',');
                        if(item.is_serial == 'yes' ){
                          serials = serials.map((slNo)=>{
                            return {serial_number : slNo}
                          })
                        }else{
                          serials = []
                        }

                
                          let updateItem = {
                            warehouse_id : item.warehouse_id,
                            warehouse_name : item.warehouse_name,
                            item_id : item.item_id,
                            item_name : item.item_name,
                            display_text : item.display_text,
                            is_serial: item.is_serial,
                            item_qty:item.item_qty,
                            item_rate:item.item_rate,
                            item_discount:item.item_discount,
                            item_discount_per:item.item_discount_per,
                            discount_acc_id:item.discount_acc_id,
                            discount_acc_name:item.discount_acc_name,
                            item_tax:item.item_tax,
                            item_tax_per:item.item_tax_per,
                            tax_acc_id:item.tax_acc_id,
                            tax_acc_name:item.tax_acc_name,
                            serials,
                            item_total:item.item_total,
                            retail_qty:item.retail_qty,
                            sale_qty:item.sale_qty,
                            sale_rate:item.sale_rate,
                            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
                            
                            done_qty_display: item.done_item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.done_retail_qty +' ' +item.base_unit_name:''),
                            done_item_qty:item.done_item_qty,
                            done_retail_qty:item.done_retail_qty,
                            done_sale_qty:item.done_sale_qty,

                            unit_symbol: item.unit_symbol,
                            base_unit_name: item.base_unit_name,
                            unit_name: item.unit_name,
                            conversion: item.conversion,
                          }
                          return updateItem;
                        });

                        master.details = itemCartData

                        return master


                })
              
              filterResultDetailsSet(data);
            }
            if(selectedFilterType != null && (selectedFilterType.filterType=='By Item' || selectedFilterType.filterType=='By Group' || selectedFilterType.filterType=='By Category')){
             
                    group_of_item_set(false)
                    let orderedItems =  res.data.map((item)=>{ 
                        let serials = []
                        
                        serials = item.serials.split(',');
                        if(item.is_serial == 'yes' ){
                          serials = serials.map((slNo)=>{
                            return {serial_number : slNo}
                          })
                        }else{
                          serials = []
                        }
                
                          let updateItem = {
                            warehouse_id : item.warehouse_id,
                            warehouse_name : item.warehouse_name,
                            item_id : item.item_id,
                            item_name : item.item_name,
                            display_text : item.display_text,
                            is_serial: item.is_serial,
                            item_qty:item.item_qty,
                            item_rate:item.item_rate,
                            item_discount:item.item_discount,
                            item_discount_per:item.item_discount_per,
                            discount_acc_id:item.discount_acc_id,
                            discount_acc_name:item.discount_acc_name,
                            item_tax:item.item_tax,
                            item_tax_per:item.item_tax_per,
                            tax_acc_id:item.tax_acc_id,
                            tax_acc_name:item.tax_acc_name,
                            serials,
                            item_total:item.item_total,
                            retail_qty:item.retail_qty,
                            sale_qty:item.sale_qty,
                            sale_rate:item.sale_rate,
                            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
                            
                            done_qty_display: item.done_item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.done_retail_qty +' ' +item.base_unit_name:''),
                            done_item_qty:item.done_item_qty,
                            done_retail_qty:item.done_retail_qty,
                            done_sale_qty:item.done_sale_qty,

                            unit_symbol: item.unit_symbol,
                            base_unit_name: item.base_unit_name,
                            unit_name: item.unit_name,
                            conversion: item.conversion,

                            group_id: item.group_id,
                            group_name: item.group_name,
                            category_id : item.category_id,
                            category_name : item.category_name,

                            acc_name: item.acc_name,
                            sale_r_id: item.sale_r_id,
                            sale_r_voucher_no: item.sale_r_voucher_no,
                          }
                          return updateItem;
                        });

                        detailsSet(orderedItems);

                        // By Group
                        if(selectedFilterType.filterType=='By Group'){
                         let getGroupWiseItems =   _.chain(orderedItems)
                            .groupBy('group_id')
                            .map(items=>{
                                return{
                                    group_name : items[0].group_name,
                                    items : _.chain(items)
                                            .groupBy('item_id')
                                            .map(item=>{
                                                return {
                                                    item_code : item[0].item_code,
                                                    item_name : item[0].item_name,
                                                    serials :  _.map(item,'serials'),
                                                    is_serial : item[0].is_serial,
                                                    base_unit_name : item[0].base_unit_name,
                                                    unit_symbol : item[0].unit_symbol,
                                                    item_qty : _.sumBy(item, (item)=> Number(item.item_qty)),
                                                    retail_qty : _.sumBy(item, (item)=> Number(item.retail_qty)),
                                                    item_total : _.sumBy(item, (item)=> Number(item.item_total)),
                                                }
                                            })
                                            .value()
                                }
                            })
                            .value()


                            getGroupWiseItems =  getGroupWiseItems.map((ele)=>{
                              ele.items =   ele.items.map(item=>{
                                    let getSerials =  item.serials.map(o =>o).flat();
                                    item.serials  = getSerials
                                    return item
                                })
                                
                                return ele
                            })

                            groupWiseItemsSet(getGroupWiseItems)
                        }


                        // By Category
                        // By Group
                        if(selectedFilterType.filterType=='By Category'){
                          let getCategoryWiseItems =   _.chain(orderedItems)
                             .groupBy('category_id')
                             .map(items=>{
                                 return{
                                  category_name : items[0].category_name,
                                     items : _.chain(items)
                                             .groupBy('item_id')
                                             .map(item=>{
                                                 return {
                                                     item_code : item[0].item_code,
                                                     item_name : item[0].item_name,
                                                     serials :  _.map(item,'serials'),
                                                     is_serial : item[0].is_serial,
                                                     base_unit_name : item[0].base_unit_name,
                                                     unit_symbol : item[0].unit_symbol,
                                                     item_qty : _.sumBy(item, (item)=> Number(item.item_qty)),
                                                     retail_qty : _.sumBy(item, (item)=> Number(item.retail_qty)),
                                                     item_total : _.sumBy(item, (item)=> Number(item.item_total)),
                                                 }
                                             })
                                             .value()
                                 }
                             })
                             .value()
 
 
                             getCategoryWiseItems =  getCategoryWiseItems.map((ele)=>{
                               ele.items =   ele.items.map(item=>{
                                     let getSerials =  item.serials.map(o =>o).flat();
                                     item.serials  = getSerials
                                     return item
                                 })
                                 
                                 return ele
                             })
 
                             categoryWiseItemsSet(getCategoryWiseItems)
                         }

                         // End category

            }

           
        })



    }


    const itemsToGroup = () =>{
        group_of_item_set(true);
        
        let getGropOfItems = _.chain(details)
                            .groupBy('item_id')
                            .map(items=>{
                                return {
                                    item_code : items[0].item_code,
                                    item_name : items[0].item_name,
                                    serials :  _.map(items,'serials'),
                                    is_serial : items[0].is_serial,
                                    base_unit_name : items[0].base_unit_name,
                                    unit_symbol : items[0].unit_symbol,
                                    item_qty : _.sumBy(items, (item)=> Number(item.item_qty)),
                                    retail_qty : _.sumBy(items, (item)=> Number(item.retail_qty)),
                                    item_total : _.sumBy(items, (item)=> Number(item.item_total)),
                                }
                            })
                            .value()

                        
                            getGropOfItems =  getGropOfItems.map((item)=>{
                                            let getSerials =  item.serials.map(o =>o).flat();
                                             item.serials  = getSerials
                                            return item
                                        })


                             groupListOfItemsSet(getGropOfItems)

                           
    }


    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Sales Return Record</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

<p style={{margin:'0px'}}>{selectedFilterType != null ?`Filter Type : ${selectedFilterType.filterType}`:''} </p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By Item'?`Item : ${selectedItem!=null?selectedItem.item_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Customer' ||  selectedFilterType.filterType == 'By Item')  ?`Customer Name : ${selectedCustomer!=null?selectedCustomer.acc_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Customer' ||  selectedFilterType.filterType == 'By Item') ?` Institution  : ${selectedCustomer!=null?selectedCustomer.institution_name:''}`:''}</p>

  
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Location' ||  selectedFilterType.filterType == 'By Item')?`Location : ${selectedLocation!=null?selectedLocation.location_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By Group'?`Group Name: ${selectedGroup!=null?selectedGroup.group_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By Category'?`Category Name: ${selectedCategory!=null?selectedCategory.category_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By User'?`User : ${selectedUser!=null?selectedUser.user_name:''}`:''}</p>

</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Customer' ||  selectedFilterType.filterType == 'By Item') ?` Address  : ${selectedCustomer!=null?selectedCustomer.address:''}`:''}</p>
<p style={{margin:'0px'}}> {selectedFilterType != null &&( selectedFilterType.filterType == 'By Customer' ||  selectedFilterType.filterType == 'By Item')?` Contact No.  : ${selectedCustomer!=null?selectedCustomer.contact_no:''}`:''}</p>
<p style={{margin:'0px'}}>Statement  :  { moment(byDateTime.dateTimeFrom).format(dateFormat)  } - { moment(byDateTime.dateTimeTo).format(dateFormat) }</p>
</div>
 


</Paper> 

{
  (selectedRecordType !=null && selectedFilterType != null &&   filterResult.length>0 &&  selectedRecordType.recordType=='without item' && (selectedFilterType.filterType == 'All' || selectedFilterType.filterType == 'By Customer' || selectedFilterType.filterType == 'By Location' || selectedFilterType.filterType == 'By User' ))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without item */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="5%">VCH No</TableCell>
            <TableCell align="left"  width="6%">Date</TableCell>
            <TableCell align="left"  width="15%">Customer/ Debtor</TableCell>
            <TableCell align="left"  width="5%">CreatedBy</TableCell>
            <TableCell align="right" width="10%">Sub Total</TableCell>
            <TableCell align="right" width="10%">Discount</TableCell>
            <TableCell align="right" width="10%">Vat</TableCell>
            <TableCell align="right" width="10%">Transport Cost</TableCell>
            <TableCell align="right" width="8%">Total</TableCell>
            <TableCell  width="11%" className="print-no">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResult.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.sale_r_voucher_no}</TableCell> 
                <TableCell align="left">{moment(data.created_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell> 
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="right">{ format(parseFloat(data.sub_total).toFixed(4))}</TableCell>
                <TableCell align="right">{ data.is_cal_type == 'on_total'?format(parseFloat(data.total_discount).toFixed(4)):'---'} </TableCell>
                <TableCell align="right">{ data.is_cal_type == 'on_total'?format(parseFloat(data.total_tax).toFixed(4)):'---'} </TableCell>
                <TableCell align="right">{format(parseFloat(data.total_transport_cost).toFixed(4))}</TableCell>
                <TableCell align="right">{format(parseFloat(data.total_amount).toFixed(4))}</TableCell>
                 <TableCell align="right" className="print-no">
                 <Link to={{pathname:`/sales/quick-sales-return-voucher/${data.sale_r_id}`}}> 
                 <ReceiptIcon style={{cursor:'pointer',color:'black',marginLeft:'2px'}} > 
                 </ReceiptIcon></Link>
                {
                  authInfo.role !='user'?(
                    <>
                     <Link to={{pathname:`/sales/sales-return-update/${data.sale_r_id}`}}>  
                     <EditIcon style={{cursor:'pointer',color:'green'}}  > </EditIcon></Link>

                    
                <DdatateIcon onClick={()=>{salesOrderDelete(data.sale_r_id)}} style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} > </DdatateIcon>
                    </>
                  ):''
                }
               
                </TableCell>
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={5} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total : </TableCell>
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format( filterResult.reduce((prev,curr)=>{
                return prev+parseFloat(curr.sub_total);
          },0).toFixed(4)) }</TableCell>
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format( filterResult.reduce((prev,curr)=>{
                return prev+(curr.is_cal_type=='on_total'?parseFloat(curr.total_discount):0);
          },0).toFixed(4)) }</TableCell>
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format( filterResult.reduce((prev,curr)=>{
                return prev+(curr.is_cal_type=='on_total'?parseFloat(curr.total_tax):0);
          },0).toFixed(4)) }</TableCell>
     
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format(filterResult.reduce((prev,curr)=>{
                return prev+parseFloat(curr.total_transport_cost);
          },0).toFixed(4)) }</TableCell>
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format(filterResult.reduce((prev,curr)=>{
                return prev+parseFloat(curr.total_amount);
          },0).toFixed(4)) }</TableCell>
          <TableCell className="print-no"></TableCell>
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}
    



   {/* with item */}
   {
        (selectedRecordType != null && selectedFilterType != null   && filterResultDetails.length>0 && selectedRecordType.recordType=='with item' && (selectedFilterType.filterType == 'All' || selectedFilterType.filterType == 'By Customer' || selectedFilterType.filterType == 'By Location' || selectedFilterType.filterType == 'By User' ))?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow >
            <TableCell align="left" width="3%">SL</TableCell>
            <TableCell align="left" width="5%">VCH</TableCell>
            <TableCell align="left" width="8%">Date</TableCell>
            <TableCell align="left" width="11%">Customer</TableCell>
            <TableCell align="left" width="10%">Item</TableCell>
            {
                authInfo.is_serial=='yes'?(<>
                             <TableCell align="left" width="10%">Serials</TableCell>
                </>):''
            }

            {
                authInfo.is_warehouse=='yes'?(<>
                                        <TableCell align="left" width="10%">Warehouse</TableCell>
                </>):''
            }
            <TableCell align="right" width="5%">Rate</TableCell>
            <TableCell align="right" width="5%">Discount%</TableCell>
            <TableCell align="right" width="5%">Vat%</TableCell>
            <TableCell align="right" width="7%"> QTY</TableCell>
            <TableCell align="right" width="8%">Total</TableCell> 
            <TableCell align="right" width="10%" className="print-no">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResultDetails.map((data,index)=>(
                <>
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.sale_r_voucher_no}</TableCell> 
                <TableCell align="left">{moment(data.created_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell>
                <TableCell align="left">{data.details[0].item_name}</TableCell>

                {
                                     authInfo.is_serial=='yes' ?(<>
                                   <TableCell align="left"> 
                                    {
                                        data.details[0].is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({data.details[0].serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                   </TableCell>
                    
                                   </>):''
                                  }

           {
                authInfo.is_warehouse=='yes'?(<>
                                  <TableCell align="right">{data.details[0].warehouse_name}</TableCell>
                </>):''
            }
               
                <TableCell align="right">{format(data.details[0].item_rate)}</TableCell>
                {
                    data.is_cal_type == 'individual' ? (<>
                         <TableCell align="right">{data.details[0].item_discount_per}%</TableCell>
                          <TableCell align="right">{data.details[0].item_tax_per}%</TableCell>
                    </>):<><TableCell colSpan={2}></TableCell></>
                  }
                <TableCell align="right">{data.details[0].qty_display}</TableCell>
                <TableCell align="right">{format((data.details[0].item_total).toFixed(4))}</TableCell>

                 <TableCell align="right" className="print-no">
                 <Link to={{pathname:`/sales/quick-sales-return-voucher/${data.sale_r_id}`}}>   <ReceiptIcon style={{cursor:'pointer',color:'black',marginLeft:'2px'}} > </ReceiptIcon></Link>
             
             {
                authInfo.role !='user'?(<>
                 <Link to={{pathname:`/sales/sales-return-update/${data.sale_r_id}`}}> 
             <EditIcon style={{cursor:'pointer',color:'green',marginLeft:'2px'}} > </EditIcon>
             </Link> 

          

                <DdatateIcon onClick={()=>{salesOrderDelete(data.sale_r_id)}} style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} > </DdatateIcon>
                </>):''
             }
            
                </TableCell>
              </TableRow>

                  
                {
                  data.details.slice(1).map((detail,dIndex)=>(
                    <TableRow key={dIndex}> 
                 
                      <TableCell  align="left"  colSpan={4}></TableCell>
                      <TableCell align="left">{detail.item_name}</TableCell>
                    {
                                   authInfo.is_serial=='yes'?(<>
                                   <TableCell align="left"> 
                                    {
                                        detail.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({detail.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                              </TableCell>
                    
                                   </>):''
                                  }


            {
                authInfo.is_warehouse=='yes'?(<>
                                             <TableCell align="right">{detail.warehouse_name}</TableCell>
                </>):''
            }
                <TableCell align="right">{format(detail.item_rate)}</TableCell>
                {
                    data.is_cal_type == 'individual' ? (<>
                         <TableCell align="right">{detail.item_discount_per}%</TableCell>
                          <TableCell align="right">{detail.item_tax_per}%</TableCell>
                    </>):<><TableCell colSpan={2}></TableCell></>
                  }
                <TableCell align="right">{detail.qty_display}</TableCell>
                <TableCell align="right">{format((detail.item_total).toFixed(4))}</TableCell>
                    </TableRow>
                  ))

                }
                    <TableRow> 
                   
                    <TableCell  style={{fontWeight:'bold',textAlign:'right'}} colSpan={authInfo.is_warehouse=='yes' && authInfo.is_serial=='yes' ? 10 :( authInfo.is_warehouse!='yes' && authInfo.is_serial!='yes' ? 8 :9 ) }> Total : </TableCell>


               
                    <TableCell  style={{fontWeight:'bold',textAlign:'left'}}> 
                       Master U. Qty = {format(parseFloat(_.sumBy(data.details,'item_qty')).toFixed(4))}, Base U. Qty = {format(parseFloat(_.sumBy(data.details,'retail_qty')).toFixed(4))}
                    </TableCell>

                  

                    <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>
                      {format(parseFloat(data.total_amount).toFixed(4))}<br/>
                  </TableCell>
                    <TableCell  align="right"  className="print-no"></TableCell>
                    </TableRow>
                   </>
                
      ))
            }

            {
                <TableRow> 
               
              <TableCell  colSpan={authInfo.is_warehouse=='yes' && authInfo.is_serial=='yes' ? 10 :( authInfo.is_warehouse!='yes' && authInfo.is_serial!='yes' ? 8 :9 ) } style={{fontWeight:'bold',textAlign:'right'}}> Grand Total  : </TableCell>

              <TableCell   style={{fontWeight:'bold',textAlign:'right'}}>Master U. Qty = {format(parseFloat(_.sumBy(filterResultDetails,({details})=>_.sumBy(details,'item_qty'))).toFixed(4))}, Base U. Qty = {format(parseFloat(_.sumBy(filterResultDetails,({details})=>_.sumBy(details,'retail_qty'))).toFixed(4))}</TableCell>

            <TableCell   style={{fontWeight:'bold',textAlign:'right'}}>{format(filterResultDetails.reduce((prev,curr)=>{
                     return prev+parseFloat(curr.total_amount)
            },0).toFixed(4))}
          </TableCell>

              <TableCell  align="right" className="print-no"></TableCell>
              </TableRow>
            }
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):''
      }




        {
  (selectedFilterType != null && group_of_item == false &&  details.length > 0  && (selectedFilterType.filterType ==  'By Item'))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    {/* sale item details */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">VCH No</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Customer</TableCell>
            <TableCell align="left">Item Name</TableCell>
            {
              authInfo.is_serial=='yes'?(<>
                          <TableCell align="left">Serials</TableCell>
              </>):''
            }
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">QTY</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right" className="print-no">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
               details.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left" width="5%">{index+parseFloat(1)}</TableCell>
                <TableCell align="left" width="10%">{data.sale_r_voucher_no}</TableCell> 
                <TableCell align="left" width="10%">{moment(data.created_date).format(dateFormat)}</TableCell>
                <TableCell align="left" width="10%">{data.acc_name}</TableCell>
                <TableCell align="left" width="10%">{data.item_name}</TableCell>
                {
                                   authInfo.is_serial=='yes'?(<>
                                   <TableCell width="15%" align="left"> 
                                    {
                                        data.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({data.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                              </TableCell>
                    
                                   </>):''
                                  }
                <TableCell align="right" width="10%">{format(parseFloat(data.item_rate).toFixed(4))}</TableCell>
                <TableCell align="right" width="10%">{data.qty_display}</TableCell>
                <TableCell align="right" width="10%">{format(parseFloat(data.item_total).toFixed(4))}</TableCell>
                 <TableCell align="right" width="8%" className="print-no"> 
                 <Link to={{pathname:`/sales/quick-sales-return-voucher/${data.sale_r_id}`}}> <ReceiptIcon style={{cursor:'pointer',color:'black',marginLeft:'2px'}} > </ReceiptIcon></Link>
               {
                 authInfo.role !='user'?(
                   <>
                <Link to={{pathname:`/sales/sales-return-update/${data.sale_r_id}`}}> 
                <EditIcon style={{cursor:'pointer',color:'green',marginLeft:'2px'}} > </EditIcon></Link>
                <DdatateIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} > </DdatateIcon>
                   </>
                 ):''
               }
             
                </TableCell>
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={authInfo.is_serial=='yes' ?6:5}></TableCell>
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}> Grand Total  : </TableCell>
        
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}> Master unit Qty = { format(parseFloat(_.sumBy(details,('item_qty'))).toFixed(4))  } , Base unit  Qty = { format(parseFloat(_.sumBy(details,('retail_qty'))).toFixed(4)) } </TableCell>
         
          <TableCell style={{fontWeight:'bold',textAlign:'right'}}>{ format(parseFloat(_.sumBy(details,('item_total'))).toFixed(4)) }</TableCell>
      <TableCell className="print-no" ></TableCell>
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}

    




          {/* Item wise Details */}
      {
        ( selectedFilterType != null && group_of_item == true  && groupListOfItems.length>0)?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow>
            <TableCell align="left" width="20%">Item Name</TableCell>
            <TableCell align="left" width="10%">Item Code</TableCell>
            {
                authInfo.is_serial=='yes' ? (<>
             <TableCell align="left" width="30%">Serials</TableCell>
                </>):''
            }
            <TableCell align="right" width="20%">QTY</TableCell>
            <TableCell align="right" width="20%">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
             groupListOfItems.map((item,ind)=>(<>
                 <TableRow  key={ind}> 
                    <TableCell align="left">{item.item_name}</TableCell>
                    <TableCell align="left">{item.item_code}</TableCell>

                    {
                                   authInfo.is_serial=='yes'?(<>
                                   <TableCell width="15%" align="left"> 
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                              </TableCell>
                    
                                   </>):''
                                  }


                    <TableCell align="right">{format(parseFloat(item.item_qty).toFixed(4))} {item.unit_symbol} , {format(parseFloat(item.retail_qty).toFixed(4))} {item.base_unit_name}</TableCell>
                    <TableCell align="right">{format(parseFloat(item.item_total).toFixed(4))}</TableCell>
                  </TableRow>
             </>))
            }


                 <TableRow  > 
                    <TableCell  colSpan={authInfo.is_serial=='yes' ?3:2} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total  : </TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>Master Unit Qty = { format(parseFloat( _.sumBy(groupListOfItems,(item)=>Number(item.item_qty))).toFixed(4))} , Base Unit Qty = {format(parseFloat(_.sumBy(groupListOfItems,(item)=>Number(item.retail_qty))).toFixed(4)) }</TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>{ format(parseFloat(_.sumBy(groupListOfItems,(item)=>Number(item.item_total))).toFixed(4))}</TableCell>
                  </TableRow>
            
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):'' 
      }

     

{
        ( selectedFilterType != null   && selectedFilterType.filterType == 'By Group' && groupWiseItems.length > 0)?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow>
            <TableCell align="center" width="40%">Item Name</TableCell>
            <TableCell align="left" width="20%">Item Code</TableCell>
            {
                authInfo.is_serial=='yes' ? (<>
             <TableCell align="left" width="20%">Serials</TableCell>
                </>):''
            }
            <TableCell align="right" width="20%">QTY</TableCell>
            <TableCell align="right" width="20%">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
                groupWiseItems.map((data,ind)=>(<>
                     <TableRow key={ind}>
                     <TableCell colSpan={12} align="center"><h4 style={{margin:'0',padding:'0'}}>Group Name : {data.group_name}</h4></TableCell>
                     </TableRow>
                     {
                data.items.map((item,ind)=>(<>
                 <TableRow  key={ind}> 
                    <TableCell align="left">{item.item_name}</TableCell>
                    <TableCell align="left">{item.item_code}</TableCell>

                    {
                                   authInfo.is_serial=='yes'?(<>
                                   <TableCell width="15%" align="left"> 
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                              </TableCell>
                    
                                   </>):''
                                  }


                    <TableCell align="right">{format(parseFloat(item.item_qty).toFixed(4))} {item.unit_symbol} , {format(parseFloat(item.retail_qty).toFixed(4))} {item.base_unit_name}</TableCell>
                    <TableCell align="right">{format(parseFloat(item.item_total).toFixed(4))}</TableCell>
                  </TableRow>
             </>))
            }

                </>))
               
            }
           


                 <TableRow  > 
                    <TableCell colSpan={authInfo.is_serial=='yes'?3:2} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total  : </TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>Master Unit Qty = { format(parseFloat( _.sumBy(groupWiseItems,({items})=>_.sumBy(items, 'item_qty') )).toFixed(4))} , Base Unit Qty = { format(parseFloat( _.sumBy(groupWiseItems,({items})=>_.sumBy(items, 'retail_qty') )).toFixed(4))}</TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>{ format(parseFloat( _.sumBy(groupWiseItems,({items})=>_.sumBy(items, 'item_total') )).toFixed(4)) }</TableCell>
                  </TableRow>
            
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):'' 
      }

{
        ( selectedFilterType != null   && selectedFilterType.filterType == 'By Category' && categoryWiseItems.length > 0 )?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow>
            <TableCell align="center" width="40%">Item Name</TableCell>
            <TableCell align="left" width="20%">Item Code</TableCell>
            {
                authInfo.is_serial=='yes' ? (<>
             <TableCell align="left" width="20%">Serials</TableCell>
                </>):''
            }
            <TableCell align="right" width="20%">QTY</TableCell>
            <TableCell align="right" width="20%">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
                categoryWiseItems.map((data,ind)=>(<>
                     <TableRow key={ind}>
                     <TableCell colSpan={12} align="center"><h4 style={{margin:'0',padding:'0'}}>Category Name : {data.category_name}</h4></TableCell>
                     </TableRow>
                     {
                data.items.map((item,ind)=>(<>
                 <TableRow  key={ind}> 
                    <TableCell align="left">{item.item_name}</TableCell>
                    <TableCell align="left">{item.item_code}</TableCell>

                    {
                                   authInfo.is_serial=='yes'?(<>
                                   <TableCell width="15%" align="left"> 
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                              </TableCell>
                    
                                   </>):''
                                  }


                    <TableCell align="right">{format(parseFloat(item.item_qty).toFixed(4))} {item.unit_symbol} , {format(parseFloat(item.retail_qty).toFixed(4))} {item.base_unit_name}</TableCell>
                    <TableCell align="right">{format(parseFloat(item.item_total).toFixed(4))}</TableCell>
                  </TableRow>
             </>))
            }

                </>))
               
            }
           


                 <TableRow  > 
                    <TableCell  colSpan={authInfo.is_serial=='yes'?3:2} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total  : </TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>Master Unit Qty = { format(parseFloat( _.sumBy(categoryWiseItems,({items})=>_.sumBy(items, 'item_qty') )).toFixed(4))} , Base Unit Qty = { format(parseFloat( _.sumBy(categoryWiseItems,({items})=>_.sumBy(items, 'retail_qty') )).toFixed(4))}</TableCell>
                    <TableCell style={{fontWeight:'bold',textAlign:'right'}}>{ format(parseFloat( _.sumBy(categoryWiseItems,({items})=>_.sumBy(items, 'item_total') )).toFixed(4)) }</TableCell>
                  </TableRow>
            
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):'' 
      }
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '4px'}}>Sales Return Record</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>

          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && selectedFilterType.filterType=='By Item'?'block':'none'}}> 
          <Autocomplete  
            autoHighlight
            size="small"
           openOnFocus={true}
           style={{width:'100%',height:'20px'}}
           options={items}
           loading={loadingItems}
           value={selectedItem}
           getOptionLabel={(option) => option.display_text}
           onChange={(event,selectedObj)=>{
              selectedItemSet(selectedObj) 
           }}
           renderInput={(params) => <TextField 
            label="Search Item" 
             onChange={e => item_name_set(e.target.value)} 
             {...params} 
             InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingItems ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
             variant="outlined"
             />} 
                 
          />
          </Grid>

         


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Customer' || selectedFilterType.filterType=='By Item')?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={customers}
             loading={loadingCustomers}
             value={selectedCustomer}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedCustomerSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Customer" 
               onChange={e => customer_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Location' || selectedFilterType.filterType=='By Item')?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}} 
              options={locations}
              value={selectedLocation}
              getOptionLabel={(option) => option.location_name}
              onChange={(event,selectedObj)=>{
                 selectedLocationSet(selectedObj);
              }}
              renderInput={(params) => <TextField                
                {...params} 
                label="Select Location" 
                variant="outlined"
                />} 
                
          />
          </Grid>
        


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && selectedFilterType.filterType=='By Group'?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={groups}
              value={selectedGroup}
              getOptionLabel={(option) => option.group_name}
              onChange={(event,selectedObj)=>{
                 selectedGroupSet(selectedObj)
              }}
              renderInput={(params) => <TextField 
                {...params} 
                label="Select Group" 
                variant="outlined"
                />} 
                
          />
          </Grid>

          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && selectedFilterType.filterType=='By Category'?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={categories}
              value={selectedCategory}
              getOptionLabel={(option) => option.category_name}
              onChange={(event,selectedObj)=>{
                 selectedCategorySet(selectedObj)
              }}
              renderInput={(params) => <TextField 
                {...params} 
                label="Select Category" 
                variant="outlined"
                />} 
                
          />
          </Grid>

         

          
       

         
        

          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && selectedFilterType.filterType=='By User'?'block':'none'}}> 
          <Autocomplete  
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={users}
              value={selectedUser} 
              getOptionLabel={(option) => option.user_name}
              onChange={(event,selectedObj)=>{
                 selectedUserSet(selectedObj)
              }}
              renderInput={(params) => <TextField 
                {...params} 
                label="Select User" 
                variant="outlined"
                />} 
                
          />
          </Grid>
{
  selectedFilterType != null && !(selectedFilterType.filterType=='By Item' || selectedFilterType.filterType=='By Group' || selectedFilterType.filterType=='By Category'  )?(
    <Grid item  xs={12}   sm={2}  > 
    <Autocomplete  
    size="small"
    autoHighlight
        openOnFocus={true}
        style={{width:'100%',height:'20px'}} 
        options={recordTypes} 
        value={selectedRecordType} 
        getOptionLabel={(option) => option.recordType}
        onChange={(event,selectedObj)=>{
          selectedRecordTypeSet(selectedObj);
        }}
        renderInput={(params) => <TextField                
          {...params} 
          label="Record Type" 
          variant="outlined"
          />} 
          
    />
    </Grid>
  ):''
}
         
{
    selectedFilterType != null && (selectedFilterType.filterType=='By Item')?(<>
         <Grid item  xs={12}   sm={1}>
                <h3 style={{cursor:'pointer',color: 'white',cursor: 'pointer',background: 'gray',marginTop: '2px'}} onClick={()=>itemsToGroup()}> GROUP </h3>
          </Grid>
        
    </>):''
}
          
      
          <Grid item  xs={12}  sm={2} style={{marginBottom: ''}} > 
          <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="From Date" 
          inputFormat={dateTimeFormat}
          value={byDateTime.dateTimeFrom}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,dateTimeFrom:datet})
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>
          </Grid>


          <Grid item  xs={12}  sm={2} style={{marginBottom: ''}} > 
          <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="To Date" 
          inputFormat={dateTimeFormat}
          value={byDateTime.dateTimeTo}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,dateTimeTo:datet})
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>
          </Grid>
          <Grid item  xs={12}   sm={1} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        Report 
                      </Button>
        </Grid>

        </Grid> 
        
        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
